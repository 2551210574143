import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import MainNavigation from '../components/ui/MainNavigation';
import NotificationsCard from '../components/ui/NotificationsCard';
import AdvertisingModal from "../components/ui/AdvertisingModal";
import { useAdvertisements } from "../context/AdvertisementContext";

const RootLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPrivacy = location.pathname.includes('/privacy-policy');
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {

      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        const data = notification.notification.data;
        if (data && data.open_url) {
          if (data.open_url.startsWith('/')) {
            navigate(data.open_url);
          } else {
            navigate('/' + data.open_url);
          }
        }
      });
    }
  }, [navigate]);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const { popupAd } = useAdvertisements();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup && popupAd) {
      setIsModalOpen(true);
      localStorage.setItem('hasSeenPopup', 'true');
    }
  }, [popupAd]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }
  return (
    <>
      {isModalOpen && popupAd && popupAd !== null && <AdvertisingModal name={popupAd.name} url={popupAd.url} imageUrl={popupAd.imageUrl || popupAd.image_url} onClose={handleCloseModal} />}
      {!isPrivacy && <MainNavigation />}
      <NotificationsCard />
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default RootLayout;

