import { createContext, useContext, useState, useEffect } from 'react';

const AdvertisementContext = createContext();

export const AdvertisementProvider = ({ children }) => {
    const [advertisements, setAdvertisements] = useState({ popupAd: null, mainTopBannerAd: null, topBannerAds: [], mainBottomBannerAd: null, bottomBannerAds: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/api/v1/advertisements/fetch/active`);
                if (response.ok) {
                    const data = await response.json();
                    setAdvertisements({
                        popupAd: data.find(item => item.type === 'POPUP'),
                        mainTopBannerAd: data.find(item => item.type === 'TOP_BANNER_MAIN'),
                        topBannerAds: data.filter(item => item.type === 'TOP_BANNER') ?? [],
                        mainBottomBannerAd: data.find(item => item.type === 'BOTTOM_BANNER_MAIN'),
                        bottomBannerAds: data.filter(item => item.type === 'BOTTOM_BANNER'),
                    });
                }
            } catch (error) {
                console.error("Error fetching advertisements:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <AdvertisementContext.Provider value={advertisements}>
            {children}
        </AdvertisementContext.Provider>
    );
}

export const useAdvertisements = () => useContext(AdvertisementContext);