import React, { useState, useEffect } from "react";

import Footer from './ui/Footer';
import AdvertisingModal from "./ui/AdvertisingModal";

import styles from './AdvertiseContent.module.css';

const AdvertiseContent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {isModalOpen && <AdvertisingModal name={'Want to advertise?'} description={'Email support@fightconnect.com to enquire'} suppressScroll={true} onClose={handleToggleModal} />}
            <div className={styles.content}>
                <div className={styles.imageContainer}>
                    <img className={styles.advertiseImage} src={process.env.PUBLIC_URL + 'advertise.png'} alt='Advertise?' />
                </div>

                <div className={styles.actions}>
                    <button onClick={handleToggleModal}>Click Here to Enquire</button>
                </div>

                <div className={styles.benefits}>
                    <div className={styles.benefitsSection}>
                        <div className={styles.benefitContainer}>
                            <h3 className={styles.benefitTitle}>🎯 Targeted Audience</h3>
                            <p className={styles.benefitDescription}>
                                Your ad reaches combat sports professionals and enthusiasts, ensuring that your brand is seen by the right people.
                            </p>
                        </div>
                        <div className={`${styles.benefitContainer} ${styles.middle}`}>
                            <h3 className={styles.benefitTitle}>📈 High Engagement</h3>
                            <p className={styles.benefitDescription}>
                                Our users are actively searching for fight-related services, giving your brand visibility where it matters most.
                            </p>
                        </div>
                        <div className={styles.benefitContainer}>
                            <h3 className={styles.benefitTitle}>💡 Customizable Ad Placements</h3>
                            <p className={styles.benefitDescription}>
                                Choose from premium banner spots, featured listings, or exclusive partnerships to maximize your exposure.
                            </p>
                        </div>
                    </div>

                    <div className={styles.benefitsSection}>
                        <div className={styles.benefitContainer}>
                            <h3 className={styles.benefitTitle}>🏆 Stand Out from Competitors</h3>
                            <p className={styles.benefitDescription}>
                                Be seen as a leader in the industry by positioning your brand alongside top fight promotions, gyms, and fighters.
                            </p>
                        </div>
                        <div className={`${styles.benefitContainer} ${styles.middle}`}>
                            <h3 className={styles.benefitTitle}>🔥 Direct Leads & Conversions</h3>
                            <p className={styles.benefitDescription}>
                                Unlike traditional advertising, our platform directly connects you with potential customers looking for your services.
                            </p>
                        </div>
                        <div className={styles.benefitContainer}>
                            <h3 className={styles.benefitTitle}>🤝 Community Trust & Credibility</h3>
                            <p className={styles.benefitDescription}>
                                Position your brand as a trusted name in combat sports by advertising on a platform where fighters, gyms, and promoters connect. Build credibility with an engaged and passionate audience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AdvertiseContent;
