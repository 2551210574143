import Modal from '../ui/Modal';

import styles from './AdvertisingModal.module.css';

const AdvertisingModal = ({ name, description, url, imageUrl, onClose, suppressScroll }) => {
  const imageSrc = imageUrl ? imageUrl : process.env.PUBLIC_URL + '/authlogo.png';

  return <Modal suppressScroll={suppressScroll} onClose={onClose}>
    <div className={styles.previewContainer}>
      {url && <div className={styles.logoSection}>
        <img className={styles.image} src={imageSrc} alt="Advertisement" />
      </div>}
      <h2>{name}</h2>
      {description && <p><strong>{description}</strong></p>}
      {url && <a className={styles.viewMore} href={url} target="_blank" rel="noreferrer">
        View More
      </a>}
    </div>
  </Modal>
}

export default AdvertisingModal;