import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useRouteLoaderData, useLocation } from 'react-router-dom';
import { Capacitor } from "@capacitor/core";
import { decodeToken } from '../../util/auth';
import { useSocket } from '../../context/SocketContext';
import styles from './MainNavigation.module.css';

const MainNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const token = useRouteLoaderData('root');
  const decodedToken = token ? decodeToken(token) : undefined;
  const [scrolledPast, setScrolledPast] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { unreadCount, unreadNotificationsCount, pendingMatchCount } = useSocket();


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrolledPast(scrollTop > 80);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getDynamicClassName = () => {
    switch (currentPath) {
      case "/":
        if (scrolledPast) {
          return styles.navbarhomeScrolled
        } else {
          return styles.navbarhome;
        }
      default:
        return "";
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const combinedClassName = `${styles.navbar} ${getDynamicClassName()}`;
  const dashboardCount = unreadNotificationsCount !== null && pendingMatchCount !== null ? unreadNotificationsCount + pendingMatchCount : 0;

  return (
    <nav className={combinedClassName}>
      <div className={styles.navbarcontainer}>
        <div className={styles.hamburger} onClick={toggleMenu}>
          {!isMenuOpen && <><span></span><span></span><span></span></>}
          {isMenuOpen && <div>X</div>}
        </div>
        <div className={`${styles.menu} ${isMenuOpen ? styles.showMenu : ''}`}>
          <ul className={styles.navbarleft}>
            <li>
              <NavLink onClick={toggleMenu} to="/">
                <img className={styles.navlogo} src={process.env.PUBLIC_URL + '/navlogo.png'} alt="Logo" />
              </NavLink>
            </li>
            {!token && <li className={styles.leftlink}>
              <NavLink onClick={toggleMenu} to="/" className={({ isActive }) => isActive ? styles.active : undefined}>
                Home
              </NavLink>
            </li>}
            {process.env.REACT_APP_SITE_PENDING !== 'true' && <li className={styles.leftlink}>
              <NavLink onClick={toggleMenu} to="/explore" className={({ isActive }) => isActive ? styles.active : undefined}>
                Explore
              </NavLink>
            </li>}
            {token && (
              <>
                <li className={styles.leftlink}>
                  <NavLink onClick={toggleMenu} to="/dashboard" className={({ isActive }) => isActive ? styles.active : undefined}>
                    Dashboard
                  </NavLink>
                  {dashboardCount > 0 && <span className={styles.unreadBadge}>{dashboardCount}</span>}
                </li>
                <li className={styles.leftlink}>
                  <NavLink onClick={toggleMenu} to="/inbox" className={({ isActive }) => isActive ? styles.active : undefined}>
                    Messages
                  </NavLink>
                  {unreadCount > 0 && <span className={styles.unreadBadge}>{unreadCount}</span>}
                </li>
              </>
            )}
            {token && decodedToken && (decodedToken.authorities[0] === 'ROLE_ADMIN' || decodedToken.authorities[0] === 'ROLE_PROMOTION') && (
              <li className={styles.leftlink}>
                <NavLink onClick={toggleMenu} to="/directory" className={({ isActive }) => isActive ? styles.active : undefined}>
                  Directory
                </NavLink>
              </li>
            )}
            {!Capacitor.isNativePlatform() && <li className={styles.leftlink}>
              <NavLink onClick={toggleMenu} to="/mobile" className={({ isActive }) => isActive ? styles.active : undefined}>
                Mobile
              </NavLink>
            </li>}
          </ul>
          <ul className={styles.navbarright}>
            {!token && (
              <>
                <li className={styles.rightlink}>
                  <NavLink onClick={toggleMenu} to="/login" className={({ isActive }) => isActive ? styles.active : undefined}>
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={toggleMenu} to="/register" className={styles.register}>
                    Register
                  </NavLink>
                </li>
              </>
            )}
            {token && (
              <>
                <li className={styles.rightlink}>
                  <NavLink onClick={toggleMenu} to="/profile" className={({ isActive }) => isActive ? styles.active : undefined}>
                    My Profile
                  </NavLink>
                </li>
                <li className={styles.rightlink}>
                  <NavLink onClick={toggleMenu} to="/accounts" className={({ isActive }) => isActive ? styles.active : undefined}>
                    Accounts
                  </NavLink>
                </li>
                {decodedToken.authorities[0] === 'ROLE_ADMIN' && (
                  <li className={styles.rightlink}>
                    <NavLink onClick={toggleMenu} to="/admin" className={({ isActive }) => isActive ? styles.active : undefined}>
                      Admin
                    </NavLink>
                  </li>
                )}
                <li className={styles.rightlink}>
                  <NavLink onClick={toggleMenu} to="/logout" className={({ isActive }) => isActive ? styles.active : undefined}>
                    Logout
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>

        {isMenuOpen && <div className={styles.overlay} onClick={toggleMenu}></div>}
      </div>
    </nav >
  );
};

export default MainNavigation;
