import { redirect } from 'react-router-dom';
import ConfirmDeleteAccountModal from '../components/users/ConfirmDeleteAccountModal';

const ConfirmAccountDeletion = () => {
    return <ConfirmDeleteAccountModal />
}

export default ConfirmAccountDeletion;

export const action = async () => {
    return redirect('../..');
}