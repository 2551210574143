import React from 'react';
import BannerAd from './BannerAd';
import { useAdvertisements } from '../../context/AdvertisementContext';
import styles from './TripleBannerAds.module.css';

const TripleBannerAds = ({ type }) => {
    const { mainTopBannerAd, topBannerAds = [], mainBottomBannerAd, bottomBannerAds = [] } = useAdvertisements();

    // Ensure exactly 1 main and 2 regular ads, filling missing ones with placeholders
    let adLeft;
    let adMain;
    let adRight;
    if (type === 'top') {
        adLeft = topBannerAds[0] || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
        adMain = mainTopBannerAd || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
        adRight = topBannerAds[1] || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
    } else {
        adLeft = bottomBannerAds[0] || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
        adMain = mainBottomBannerAd || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
        adRight = bottomBannerAds[1] || { url: "/advertise", imageUrl: process.env.PUBLIC_URL + "/placeholder-ad.png" };
    }

    return (
        <div className={styles.tripleBannerAds}>
            {/* Mobile View: Only show the main banner (or placeholder) */}
            <div className={styles.mobileView}>
                 <BannerAd
                    url={adMain?.url || "/advertise"}
                    imageUrl={adMain?.imageUrl || adMain?.image_url || process.env.PUBLIC_URL + "/placeholder-ad.png"}
                />
            </div>

            {/* Desktop View: Show main banner centered, regular banners on sides */}
            <div className={styles.desktopView}>
                <div className={styles.adLeft}>
                    <BannerAd url={adLeft.url} imageUrl={adLeft.imageUrl || process.env.PUBLIC_URL + "/placeholder-ad.png"} />
                </div>

                <div className={styles.adMain}>
                    <BannerAd
                        url={adMain?.url || "/advertise"}
                        imageUrl={adMain?.imageUrl || adMain?.image_url || process.env.PUBLIC_URL + "/placeholder-ad.png"}
                    />
                </div>

                <div className={styles.adRight}>
                    <BannerAd url={adRight.url} imageUrl={adRight.imageUrl || process.env.PUBLIC_URL + "/placeholder-ad.png"} />
                </div>
            </div>
        </div>
    );
};

export default TripleBannerAds;
