import React from "react";

import MobileContent from "../components/MobileContent";

const Mobile = () => {
  return (
    <MobileContent />
  );
};

export default Mobile;
