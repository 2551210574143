import { Link } from 'react-router-dom';

import styles from './BannerAd.module.css';

const BannerAd = ({ url, imageUrl }) => {
    return <div className={styles.bannerAd}>
        {url !== '/advertise' && <a href={url} target="_blank" rel="noreferrer">
            <img alt="Advertisement" src={imageUrl} />
        </a>}
        {url === '/advertise' && <Link to={url}>
            <img alt="Advertisement" src={imageUrl} />
        </Link>}
    </div>
}

export default BannerAd;