import { redirect } from 'react-router-dom';

import AuthForm from '../components/users/AuthForm';

const Register = () => {
  return (
    <AuthForm />
  );
}

export default Register;

export const action = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get("email");
  return redirect('/email-unconfirmed/' + email);
};
