import { redirect, useLocation, Outlet } from 'react-router-dom';
import ConfirmModal from '../components/ui/ConfirmModal';

const RequestDeletion = () => {
    const location = useLocation();
    const isConfirm = location.pathname.includes('/confirm');
    return isConfirm ? <Outlet /> : <ConfirmModal title="Delete Account?" />
}

export default RequestDeletion;

export const action = async () => {
    return redirect('confirm')
}