import React from "react";

import AdvertiseContent from "../components/AdvertiseContent";

const AdvertiseWithUs = () => {
  return (
    <AdvertiseContent />
  );
};

export default AdvertiseWithUs;
