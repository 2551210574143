const options = [
  "Cage Hire", 
  "Security", 
  "Venue", 
  "Film Crew", 
  "Photographer",
  "Judge", 
  "Referee", 
  "Commissioner", 
  "Cutmen", 
  "Handwrapper", 
  "Commentators", 
  "Runner"
];

export default options;