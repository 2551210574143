
import {checkAuthLoader } from '../util/auth'

import {useEffect, useState} from 'react';
import { redirect } from 'react-router-dom';
import { Capacitor } from "@capacitor/core";
import HomeContent from "../components/HomeContent";
import MobileContent from "../components/MobileContent";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile && !Capacitor.isNativePlatform() ? <MobileContent /> : <HomeContent />;
};

export default Home;

export const loader = async () => {
  const token = await checkAuthLoader();
  if (token instanceof Response) {
    return null;
  }
  return redirect('/dashboard');
}