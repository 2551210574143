import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import styles from './MobileContent.module.css';

const MobileContent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={styles.content}>
                <h1>Download our App on iOS and Android</h1>
                <h3>An optimized mobile experience with push notifications</h3>
                <div className={styles.downloads}>
                    <a href="https://apps.apple.com/app/id6740096546" target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/appstore.svg`} alt="Download on the App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.fightconnect.app" target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/googleplay.png`} alt="Get it on Google Play" />
                    </a>
                </div>
                <Link className={styles.exploreLink} to="/explore">
                    Explore on mobile browser (not recommended)
                </Link>
            </div>
        </>
    );
};

export default MobileContent;
