import { useEffect } from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import TripleBannerAds from './ui/TripleBannerAds';
import Footer from "../components/ui/Footer";

import Typist from 'react-text-typist';

import styles from './HomeContent.module.css';

const HomeContent = () => {
  const token = useRouteLoaderData('root');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TripleBannerAds type="top" />
      <div className={styles.heroContainer}>
        <div
          className={styles.heroImage}
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL + '/homepage-top.png'}")`,
          }}
        ></div>
        <div className={styles.heroTextContainer}>
          <h1 className={styles.heroHeading}>Fight Connect</h1>
          <div className={styles.heroParagraph}>
            <p>
              Efficient{' '}
              <Typist sentences={['Matchmaking', 'Fighting', 'Events']} loop={true} typingSpeed={50} deleteSpeed={100} pauseTime={2000} showCursor={false} />
            </p>
          </div>

          <div>
            {token === null ? (
              <Link className={styles.heroCTA} to="/register">
                Join Now
              </Link>
            ) : (
              process.env.REACT_APP_SITE_PENDING === 'true' ? (<Link className={styles.heroCTA} to="/">
                Explore Coming Soon
              </Link>) : (<Link className={styles.heroCTA} to="/explore">
                Explore Now
              </Link>)
            )}
          </div>
        </div>
      </div>
      {/* <div className={styles.servicesSection}>
        <h2 className={styles.servicesHeading}>Our Services</h2>
        <div className={styles.serviceContainers}>
          <div className={styles.serviceContainer}>
            <h3 className={styles.serviceTitle}>Interactive Map</h3>
            <p className={styles.serviceDescription}>
              Use our explore page to find gyms, events, promotions and fighters from around the globe. View a gym's available fighters and their stats.
            </p>
          </div>
          <div className={`${styles.serviceContainer} ${styles.middle}`}>
            <h3 className={styles.serviceTitle}>Automated Matching</h3>
            <p className={styles.serviceDescription}>
              Our algorithm will suggest the best matchups for events based on active fighter stats. Requests are then sent for all involved parties to accept or decline.
            </p>
          </div>
          <div className={styles.serviceContainer}>
            <h3 className={styles.serviceTitle}>Fighter Substitution</h3>
            <p className={styles.serviceDescription}>
              Gyms and promotions can put forward substitutes for each fight in the case of fighter pullouts which can be accepted or declined by the organiser.
            </p>
          </div>
        </div>
      </div> */}
      <div className={styles.missionSection}>
        {/* Left Section */}
        <div className={styles.missionText}>
          <h2>Connecting the Combat Sports World</h2>
          <p>
            At Fight Connect, our goal is to create a straightforward and efficient online platform that connects fighters, gyms, and event promoters in the combat sports community. We aim to make the process of finding suitable matches and opportunities easier for everyone involved.
          </p>
          <h3>Our main objectives are to:</h3>
          <ul className={styles.missionList}>
            <li>Streamline the process of finding suitable matches for fighters, training facilities, and events.</li>
            <li>Provide a central hub for fighters, coaches, and gyms to showcase their talents and connect with others.</li>
            <li>Support event promoters by offering a tool to find and manage appropriate match-ups.</li>
            <li>Create an environment for people of all backgrounds and skill levels in combat sports.</li>
            <li>Keep improving our platform to stay current with the needs of our users.</li>
          </ul>
          <p>
            At Fight Connect, we are committed to simplifying the world of combat sports by connecting fighters, gyms, and event promoters effectively and efficiently.
          </p>
        </div>

        {/* Right Section */}
        <div className={styles.missionImageContainer}>
          <video
            className={styles.previewVideo}
            controls
          >
            <source src="https://fc-media-bucket.s3.eu-west-1.amazonaws.com/FightConnectPreview.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <img className={styles.homeImg1} src={process.env.PUBLIC_URL + 'homepage-img1.png'} alt='Fight Connect' />
      <div className={styles.benefitsSection}>
        <div className={styles.benefitsContainer}>
          {/* Column 1: For Gyms */}
          <div className={styles.benefitsColumn}>
            <h3 className={styles.benefitsTitle}>For Gyms</h3>
            <ul className={styles.benefitsList}>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Add your gym and fighters for promotions to invite to events</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Find local and national events for your fighters to gain reputation on</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Get notified when events are added to the map, for you to put forward your best fighters</li>
            </ul>
          </div>

          {/* Column 2: For Fighters */}
          <div className={styles.benefitsColumn}>
            <h3 className={styles.benefitsTitle}>For Fighters</h3>
            <ul className={styles.benefitsList}>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Have access to the full explore page where you can view fellow fighter stats, gyms, along with current and past events</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Find an event that you think you could add to your record</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Get invites to fight on the best promotions as you build a name in the combat sports world</li>
            </ul>
          </div>

          {/* Column 3: For Promotions */}
          <div className={styles.benefitsColumn}>
            <h3 className={styles.benefitsTitle}>For Promotions</h3>
            <ul className={styles.benefitsList}>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Add your events to the explore page for all gyms and fighters to see</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Invite fighters from any gym to create the best and most exciting fights</li>
              <li className={styles.benefitsItem} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/redtick.png)` }}>Send instant notifications to gyms as soon as your event is posted</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HomeContent;