import React, { useState, useEffect } from 'react';
import { useLoaderData, useParams, useNavigate } from 'react-router-dom';

import DirectoryItemModal from './DirectoryItemModal';

import styles from './DirectoryResultsContent.module.css';

const DirectoryResultsContent = () => {
  const { region, type } = useParams();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [previewItem, setPreviewItem] = useState({});
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [searchData, setSearchData] = useState('');
  const navigate = useNavigate();

  const results = useLoaderData();

  const kebabToTitleCase = (input) => {
    const lowerCaseWords = ['of', 'and', 'in', 'the', 'to']; // Add more words as needed

    return input
      .split('-')
      .map(part => {
        return lowerCaseWords.includes(part.toLowerCase())
          ? part.toLowerCase()
          : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      })
      .join(' ');
  }

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showTopBtn && window.pageYOffset > 100) {
        setShowTopBtn(true);
      } else if (showTopBtn && window.pageYOffset <= 100) {
        setShowTopBtn(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showTopBtn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const changeSearchDataHandler = (event) => {
    setSearchData(event.target.value);
  }

  const handleSelect = (item) => {
    setPreviewItem(item);
    setIsPreviewOpen(true);
  }

  const handleCloseModal = () => {
    setPreviewItem({});
    setIsPreviewOpen(false);
  }

  const filteredResults = results.filter(result => {
    const trimmedSearchData = searchData.trim().toLowerCase();
    const lowerCaseName = result.name.toLowerCase();
    return trimmedSearchData === '' || lowerCaseName.includes(trimmedSearchData);
  });

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>
      {showTopBtn && (
        <button
          className={styles.scrollTopBtn}
          onClick={scrollToTop}
        >
          <strong>Go back to top</strong>
        </button>
      )}
      <div className={styles.container}>
        <h4 className={styles.backBtn} onClick={handleBack}>{'<< Go Back'}</h4>
        {region === 'all' && <h1>Results for {kebabToTitleCase(type)}</h1>}
        {region !== 'all' && <h1>Results for {kebabToTitleCase(type)} in {kebabToTitleCase(region)}</h1>}
        <div className={styles.search}>
          <input
            id="search"
            type="text"
            name="search"
            value={searchData}
            onChange={changeSearchDataHandler}
            autoComplete='new-password'
            placeholder="Search for a service by name..."
          />
        </div>
        {isPreviewOpen && (
          <DirectoryItemModal
            previewItem={previewItem}
            onClose={handleCloseModal}
          />
        )}
        <div className={styles.results}>
          {filteredResults.map(item => (
            <div key={item.id} className={styles.resultItem}>
              <h3>{item.name}</h3>
              <button className={styles.viewBtn} onClick={() => {
                handleSelect(item);
              }}>
                View
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DirectoryResultsContent;
