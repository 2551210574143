import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import styles from './Modal.module.css'

const Modal = ({ children, onClose, suppressScroll }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!suppressScroll || suppressScroll === false) {
      window.scrollTo(0, 0);
    }
  }, [suppressScroll]);

  const closeHandler = () => {
    navigate('..')
  }

  return <>
    <div className={styles.backdrop} onClick={onClose ? onClose : closeHandler} />
    <dialog open className={styles.modal}>
      <button className={styles.closeButton} onClick={onClose ? onClose : closeHandler}>X</button>
      {children}
    </dialog>
  </>
}

export default Modal;